/* eslint-disable vue/html-self-closing */
/* eslint-disable vue/html-self-closing */
<template>
  <div class="w-100">
    <AppNavbarFull
      v-if="showLayout"
      ref="refAppNavbarFull"
      :company-image="companyImage"
    />

    <b-row class="mx-2 my-2">
      <b-button
        variant="primary"
        class="mx-1"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="goBack"
      >Go Back</b-button>
    </b-row>

    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <div
        class="d-flex flex-column flex-md-row px-0 px-md-2 position-relative"
        style="min-height: 70vh;"
      >
        <b-col
          v-if="!$isEmpty(event_data)"
          cols="12"
          sm="12"
          md="8"
          class="m-0 p-0 "
          :class="inheritedEventID ? 'md:w-100' : ''"
        >

          <b-card
            v-if="event_data.event_category !== 'group'"
            class="job-view p-0 p-md-2"
            :class="event_data.active ? '': 'blurred-image'"
          >

            <div
              v-if="
                event_data.event_category === 'single' &&
                  event_data.image_url.length > 0 &&
                  event_data.image_url !== 'default'
              "
              slot="no-body"
            >
              <img
                :src="
                  event_data.image_url == 'default'
                    ? require('@/assets/images/placeholder/no-image.jpg')
                    : event_data.image_url
                "
                alt="content-img"
                class="responsive h-96"
              >
            </div>

            <div class="d-flex flex-column flex-md-row justify-content-between align-items-start">
              <div
                v-if="event_data.published_fields.includes('employer')"
                class="d-flex flex-wrap"
                style=""
              >
                <span class="m-0 p-0 text-regular font-weight-bolder">{{ event_data.company_name }}</span>
                <div class="flex-break" />
                <span class="m-0 p-0 text-primary text-large font-weight-bolder">{{ event_data.position }}</span>
              </div>
              <div
                v-else
                class="d-flex flex-wrap"
                style="width: 100%;"
              >
                <span class="m-0 p-0 text-regular font-weight-bolder font-italic">Confidential</span>
                <div class="flex-break" />
                <span class="m-0 p-0 text-primary text-large font-weight-bolder">{{ event_data.position }}</span>
              </div>

              <div
                class="my-1 my-md-0 d-flex justify-content-md-end align-items-end w-md-25 w-100"
                style="gap: 0;"
              >

                <span
                  v-if="event_data.published_fields.includes('salary')"
                  class="m-0 p-0 d-flex text-large font-weight-bolder"
                >
                  ${{ $formatCurrency(event_data.min_salary) }} - ${{ $formatCurrency(event_data.max_salary) }}
                </span>
                <span
                  v-else
                  class="m-0 p-0 d-flex text-large font-weight-bolder font-italic"
                >
                  Confidential
                </span>

                <span
                  v-if="event_data.published_fields.includes('salary')"
                  class="mx-0 p-0 text-regular"
                  style="margin-bottom: .2rem;"
                ><i class="m-0 p-0">{{ event_data.salary_cycle }}</i></span>
              </div>
            </div>

            <div class="d-flex mt-1 flex-wrap flex-gap-md">
              <div class="d-flex flex-gap-sm justify-content-between">
                <font-awesome-icon
                  icon="map-marker-alt"
                  class=""
                />
                <h6 class="icon-header">
                  {{
                    ['remote_only'].includes(event_data.working_location_type)
                      ? cleanLocation(
                        {
                          'address': event_data.working_location_address,
                          'country': event_data.working_location_country,
                          'state': event_data.working_location_state,
                          'postal_code': event_data.working_location_postal_code,
                        },
                        event_data.location_zone
                      )
                      : 'Remote'
                  }}
                </h6>
              </div>

              <div class="d-flex flex-gap-sm justify-content-between">
                <feather-icon
                  v-b-tooltip.hover.top="'Job Type'"
                  icon="ClockIcon"
                />

                <h6
                  class="icon-header"
                >{{ event_data.job_type }}</h6>
              </div>

              <div class="d-flex flex-gap-sm justify-content-between">
                <feather-icon
                  v-b-tooltip.hover.top="'Position Level'"
                  icon="AwardIcon"
                />

                <h6
                  class="icon-header"
                >{{ event_data.position_level ? event_data.position_level : "NA" }}</h6>
              </div>

              <div class="d-flex flex-gap-sm justify-content-between">
                <feather-icon
                  v-b-tooltip.hover.top="'Job Industry'"
                  icon="BriefcaseIcon"
                />

                <h6
                  class="icon-header"
                >{{ $isNotEmpty(event_data.industry) ? event_data.industry.join(", "): "NA" }}</h6>
              </div>

              <!-- working_days -->
              <div
                v-if="$isNotEmpty(event_data.working_days)"
                class="d-flex flex-gap-sm justify-content-between"
              >
                <feather-icon
                  v-b-tooltip.hover.top="'Working Days'"
                  icon="CalendarIcon"
                />

                <h6
                  class="icon-header"
                >{{ event_data.working_days }}</h6>
              </div>

              <!-- working_time -->
              <div
                v-if="$isNotEmpty(event_data.working_time)"
                class="d-flex flex-gap-sm justify-content-between"
              >
                <feather-icon
                  v-b-tooltip.hover.top="'Working Hours'"
                  icon="ClockIcon"
                />

                <h6
                  class="icon-header"
                >{{ event_data.working_time }}</h6>
              </div>
            </div>

            <!-- employment_type -->
            <div
              class="mt-1 mx-0 d-flex flex-wrap flex-gap-xs"
            >
              <div
                v-for="(tag, index) in event_data.employment_type"
                :key="index"
                class="cursor-default"
              >
                <b-badge
                  v-b-tooltip.hover.top="'Employment Type'"
                  pill
                  color="primary"
                >
                  {{ capitalize(tag) }}
                </b-badge>
              </div>
            </div>

            <!-- industry -->
            <!-- <template v-if="$length(event_data.industry)"> -->
            <template v-if="false">
              <b-row class="m-0 p-0 mt-1 d-flex flex-wrap flex-gap-xs">
                <div
                  v-for="(tag, index) in event_data.industry"
                  :key="index"
                  v-b-tooltip.hover.top="'Industry'"
                  class="cursor-default"
                >
                  <b-badge
                    pill
                    variant="dark"
                  >
                    {{ tag }}
                  </b-badge>
                </div>
              </b-row>
            </template>

            <div class="mt-1 mb-1 d-lg-flex flex-gap-md d-block">
              <div>{{ event_data.number_of_applications ? event_data.number_of_applications : 0 }} Applications</div>
              <div>Posted on: {{ $formatDate(event_data.created_on) }}</div>
              <div>Closing on: {{ $isEmpty(event_data.expires_on) ? $formatDate(event_data.expires_on) : '-' }}</div>
            </div>

            <div
              v-if="['mcf', 'efc'].includes(event_data.from_source)"
              class="d-flex mt-1"
            >
              <h6
                class="text-warning font-weight-bold"
                style="font-weight: 600 !important"
              >Source: {{ event_data.from_source === 'mcf' ? 'MyCareersFuture': 'eFinancialCareers' }}</h6>
              <font-awesome-icon
                v-b-tooltip.hover.top="mcf_message"
                icon="question-circle"
                class="ml-1 text-warning"
              />
            </div>

            <div
              v-if="!inheritedEventID"
              class="m-0 p-0 mt-2 d-flex flex-row flex-gap-md w-100"
            >
              <div
                class="m-0 p-0 w-100"
              >
                <b-button
                  variant="main"
                  icon-pack="feather"
                  icon="icon-link-2"
                  class="mt-1 w-100 shadow-sm"
                  :disabled="event_data.active ? false: true"
                  @click="onCopy(event_data.id)"
                >Share Job</b-button>
              </div>
              <div
                class="m-0 p-0 w-100"
              >
                <b-button
                  v-if="
                    checkIDRegistered(registered, event_data.id)
                  "
                  ref="join"
                  class="mx-auto mt-1 w-100 md:w-1/2"
                  variant="success"
                  icon-pack="feather"
                  icon="icon-check"
                  :disabled="event_data.active ? false: true"
                  @click="joinEvent(event_data, $event)"
                >Applied</b-button>
                <b-button
                  v-else-if="event_data.external"
                  ref="join"
                  class="mx-auto mt-1 w-100 md:w-1/2"
                  variant="primary"
                  icon-pack="feather"
                  icon="icon-check"
                  :disabled="event_data.active ? false: true"
                  @click="fireRedirect(event_data, $event)"
                >Apply Now</b-button>

                <div
                  v-else
                  class="vx-col w-100 md:w-1/2"
                >
                  <b-button
                    ref="join"
                    class="w-100 mt-1"
                    variant="primary"
                    :disabled="event_data.active ? false: true"
                    @click="joinEvent(event_data, $event)"
                  >Apply Now</b-button>
                </div>
              </div>
            </div>

            <hr v-if="event_data.note">
            <b-row v-if="event_data.note">
              <p
                style="font-weight: 600; font-size: 14px;white-space: pre-line;"
                class="mx-2 mt-1"
              >
                <span>
                  Note:
                </span>
                <br>
                <span>
                  {{ event_data.note }}
                </span>

              </p>
            </b-row>

            <hr>
            <h6
              v-if="['mcf', 'efc'].includes(event_data.from_source)"
              class="my-1 font-weight-bold"
            >Job Description and Requirements</h6>
            <h6
              v-else
              class="my-1 font-weight-bold"
            >Job Description</h6>

            <!-- v-if="['mcf', 'efc'].includes(event_data.from_source)" -->
            <div
              v-if="['efc'].includes(event_data.from_source)"
              class="mt-1 mx-0 mx-md-2"
              style="white-space: normal; list-style-position: inside;"
            >
              <div
                style="white-space: normal; list-style-position: inside;"
                v-html="event_data.description"
              />
            </div>
            <div
              v-else
              class="mt-1 mx-0 mx-md-2"
              style="white-space: normal; list-style-position: inside;"
            >
              <ul
                v-for="(item, index) in cleanedJobDetails(
                  event_data.description
                )"
                :key="index"
                class="m-0 p-0"
              >
                <div
                  v-if="['mcf', 'efc'].includes(event_data.from_source)"
                >{{ item }}</div>

                <li
                  v-else
                >{{ item }}</li>
              </ul>
            </div>
            <!-- <div
              class="mt-1 mx-0 mx-md-2 event-description-format"
              style="white-space: normal; list-style-position: inside;"
              v-html="event_data.description"
            /> -->

            <h6
              v-if="!['mcf', 'efc'].includes(event_data.from_source)"
              class="my-1 font-weight-bold"
            >Job Requirements</h6>

            <div
              v-if="!['mcf', 'efc'].includes(event_data.from_source)"
              class="mt-1 mx-0 mx-md-2"
              style="white-space: normal; list-style-position: inside;"
            >
              <ul
                v-for="(item, index) in cleanedJobDetails(
                  event_data.requirements
                )"
                :key="index"
              >
                <li
                  class=""
                >{{ item }}</li>
              </ul>
            </div>

            <h6
              v-if="
                event_data.applicant_description &&
                  event_data.applicant_description.length > 1
              "
              class="my-1 font-weight-bold"
            >Brief Description Of Candidate</h6>
            <div
              v-if="
                event_data.applicant_description &&
                  event_data.applicant_description.length > 1
              "
              class="d-flex mt-1 mx-0 mx-md-2"
            >
              <p
                class="m-0 p-0"
              >{{ event_data.applicant_description }}</p>
            </div>

            <template v-if="false">
              <hr>
              <h6
                class="my-1 font-weight-bold"
              >Job Type</h6>
              <div class="d-flex mx-auto">
                <b-badge
                  pill
                  variant="primary"
                  class="ml-1 mr-1"
                >{{
                  capitalize(event_data.job_type)
                }}</b-badge>
              </div>
            </template>

            <div
              v-if="false"
              class="my-1 d-flex justify-content-between w-100"
            >
              <h6
                class="w-50"
              >Working Location:</h6>
              <p
                class="m-0 p-0 w-50 text-right"
              >{{ event_data.working_location_address == event_data.working_location_postal_code
                 ? `${event_data.working_location_state},
                    ${event_data.working_location_country},
                    ${event_data.working_location_postal_code}`
                 : `${$isNotEmpty(event_data.working_location_address) ? event_data.working_location_address + ',' : ``}
                    ${$isNotEmpty(event_data.working_location_state) ? event_data.working_location_state + ',' : ''}
                    ${$isNotEmpty(event_data.working_location_country) ? event_data.working_location_country + ',' : ''}
                    ${$isNotEmpty(event_data.working_location_postal_code) ? event_data.working_location_postal_code.replace('999999', '') : ''}`
               }}<br>
                <span
                  v-if="event_data.working_location_type === 'remote_only' || event_data.working_location_type === 'hybrid'"
                  class="font-weight-bolder"
                >(Working from home allowed)</span>
              </p>
            </div>

            <hr v-if="event_data.cleaned_start_date">
            <div
              v-if="event_data.cleaned_start_date"
              class="d-flex justify-content-between w-100"
            >
              <h6
                class="mt-1 w-50"
              >Event Period:</h6>

              <p class="font-semibold w-50 text-right">
                {{ event_data.cleaned_start_date }} to
                {{ event_data.cleaned_end_date }}
              </p>
            </div>

            <hr v-if="event_data.cleaned_start_time">
            <div
              v-if="event_data.cleaned_start_time"
              class="d-flex justify-content-between w-100"
            >
              <h6
                class="mt-1 w-50"
              >Event Time:</h6>

              <p class="font-semibold w-50 text-right">
                {{ event_data.cleaned_start_time }} to
                {{ event_data.cleaned_end_time }}
              </p>
            </div>

            <div
              v-if="false"
              class="mt-1"
            >
              <p
                class="font-weight-bolder"
              >Brought To You By :</p>

              <div
                class="d-flex flex-gap-md w-100"
                :class="$isEmpty(event_data.company_summary) ? 'align-items-center' : ''"
              >
                <div>
                  <b-avatar
                    square
                    class="block sm:w-24 sm:h-20 md:w-24 md:h-24"
                    size="40px"
                    :src="
                      event_data.company_image_url == 'default'
                        ? require('@/assets/images/placeholder/no-image.jpg')
                        : event_data.company_image_url
                    "
                  />
                </div>
                <div
                  class="m-0 p-0 w-100"
                >
                  <h5
                    class="m-0 p-0 text-left text-primary font-weight-bolder"
                    style="line-height: 1rem;"
                  >{{ event_data.company_name }}</h5>
                  <p
                    class="m-0 p-0 text-left italic font-weight-lighter"
                  >{{ event_data.company_summary }}</p>
                </div>
              </div>
            </div>
          </b-card>
          <div
            v-if="!event_data.active"
            class="overlay-text"
          >
            This job has been closed.
          </div>
        </b-col>

        <b-col
          v-if="!inheritedEventID"
          cols="12"
          sm="12"
          md="4"
          class="m-0 p-0 mx-0 mx-md-1"
        >
          <h5 class="mb-2">
            Suggested Jobs
          </h5>
          <div
            v-if="!$isEmpty(suggestions) && suggestions.length > 0"
            class="h-auto"
          >
            <b-card
              v-for="(item, index) in suggestions"
              :key="index"
              class="suggested-job shadow-md cursor-pointer"
              @click="suggestedLink(item.id)"
            >
              <h6
                v-if="item.published_fields.includes('employer')"
                class="font-medium italic"
              >{{ item.company_name }} </h6>
              <h6
                v-else
                class="font-medium font-italic"
              >Confidential</h6>

              <div class="d-flex flex-column flex-gap-sm">
                <p
                  class="m-0 p-0 mt-1 text-primary text-large font-weight-bolder"
                >{{ item.position }}</p>

                <div
                  class="d-flex flex-wrap flex-gap-sm"
                  style="margin-block: .2rem;"
                >
                  <div
                    v-for="(tag, index) in item.employment_type"
                    :key="index"
                  >
                    <b-badge
                      pill
                      color="warning"
                    >
                      {{ capitalize(tag) }}
                    </b-badge>
                  </div>
                </div>
              </div>

              <div
                class="d-flex flex-wrap mt-1 flex-gap-sm"
                :class="{'flex-wrap': isMobile || isTablet}"
              >
                <div class="d-flex">
                  <font-awesome-icon
                    icon="map-marker-alt"
                    class=""
                  />
                  <h6 class="icon-header">
                    {{
                      cleanLocation(
                        item.work_location,
                        item.location_zone
                      )
                    }}
                  </h6>
                </div>

                <div
                  v-if="item.published_fields.includes('salary')"
                  class="d-flex"
                >
                  <font-awesome-icon
                    icon="dollar-sign"
                    class=""
                  />
                  <h6
                    v-if="item.min_salary == item.max_salary"
                    class="icon-header"
                  >
                    {{ item.max_salary === 1 ? "Undisclosed" : item.max_salary }}
                    {{
                      item.max_salary === 1 ? "" : cleanCycles(item.salary_cycle)
                    }}
                  </h6>

                  <h6
                    v-else-if="item.max_salary === 'To be discussed later'"
                    class="icon-header"
                  >
                    {{ item.max_salary }}
                  </h6>

                  <h6
                    v-else
                    class="icon-header"
                  >
                    {{ item.min_salary }} - {{ item.max_salary }}
                    {{ cleanCycles(item.salary_cycle) }}
                  </h6>
                </div>

                <div
                  v-else
                  class="d-flex"
                >
                  <font-awesome-icon
                    icon="dollar-sign"
                    class=""
                  />
                  <h6
                    class="icon-header"
                  >
                    Confidential
                  </h6>
                </div>

              </div>
            </b-card>
          </div>

          <div v-else>
            <b-card>
              <h5>No suggestions available for this position</h5>
            </b-card>
          </div>
        </b-col>

        <!-- <b-col
          v-else-if="event_data.event_status === 'closed'"
          cols="12"
          sm="12"
          md="8"
          class="text-center py-4"
        >
          <b-card>
            <h2 class="mb-1 w-00">
              Event Has Been Closed
            </h2>
            <div class="mt-2">
              <b-button
                variant="primary"
                class="mx-1 mb-1 btn-sm-block"
                :to="{name: 'listings-page'}"
              >Go To Listing Page</b-button>
            </div>
          </b-card>
        </b-col> -->
      </div>
    </b-overlay>

    <b-row>
      <div class="">
        <b-modal
          ref="popupReg"
          v-model="popupReg"
          centered
          hide-footer
          title="Join Event"
          size="lg"
        >
          <div class="w-100">
            <h6 class="mb-6">
              You will require a HeadHunters HQ account to proceed with the
              application.
            </h6>
            <b-tabs justified>
              <b-tab title="Login">
                <login-page
                  class="w-100"
                  which-page="listings"
                  :event-id="this.$router.currentRoute.path"
                  @user-logged-in="afterUserLoggedIn"
                />
              </b-tab>
              <b-tab title="Registration">
                <RegistrationForm
                  class="w-100"
                  :extra-data="popup_questions"
                  :event-id="event_id"
                  which-page="listings"
                  account-type="Applicant"
                  @user-registered="afterUserRegistered"
                />
              </b-tab>
            </b-tabs>
          </div>
        </b-modal>
      </div>
    </b-row>
    <!-- Quiz Modal -->
    <JobPopup
      :activate-popup="popup_quiz"
      :job-questions="popup_questions"
      :save-nric="save_nric"
      :all-applications="registered"
      @application-form="checkForm"
      @application-update="applicationUpdate"
    />
    <ProfileCompletionForm
      :activate-popup="popupProfileQuestions"
      @open-job-form="getQuestions"
      @open-completion-form="activateTypeForm"
    />

    <div class="container-fluid">
      <AppFooter class="w-100" />
    </div>
    <template>
      <script
        type="application/ld+json"
        v-html="jsonld"
      />
    </template>

  </div>
</template>

<script>
import { upperFirst } from 'lodash'
import platform from 'platform'
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BModal,
  VBModal,
  BBadge,
  BAvatar,
  BOverlay,
  BTabs,
  VBTooltip,
  BTab,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { allIndustries } from '@/constants/datastore'
import AppNavbarFull from '@/layouts/components/AppNavbarFull.vue'
import AppFooter from '@/layouts/components/AppFooter.vue'

import JobPopup from '@/components/JobPopup.vue'
import LoginPage from '@/views/authentication/login/LoginForm.vue'
import RegistrationForm from '@/views/authentication/registration/RegistrationForm.vue'
import { onResizeMixins } from '@/components/mixins/onResizeMixins'
import { listingsMixin } from './mixins/listingsMixins'

export default {
  components: {
    JobPopup,
    LoginPage,
    RegistrationForm,
    AppNavbarFull,
    AppFooter,
    BCard,
    BButton,
    BRow,
    BCol,
    BModal,
    BBadge,
    BAvatar,
    BOverlay,
    BTabs,
    BTab,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  mixins: [listingsMixin, onResizeMixins],
  props: {
    inheritedEventID: { type: String, default: '' },
  },
  data() {
    return {
      companyImage: '',
      specified_event: {},
      jsonld: {},
      popup_image: '',
      book: {
        text: 'Register',
        color: 'primary',
      },
      event_data: {},
      event_id: '',
      fullname: '',
      email_address: '',
      show_inside: true,
      popupActive: false,
      image_url: 'default',
      event_exist: false,
      action: 'insert',
      current_item: {},
      popupReg: false,
      item_id: '',
      companies: [],
      registered: [],
      description_manager: {},
      inside_data: {},
      all_event_records: {},
      event_groups: [],

      popup_event: {},

      select_event_type: '',
      event_types: [
        { text: 'Single events', value: 'single' },
        { text: 'Group events', value: 'group' },
      ],
      select_tags: [],
      select_date: [],
      selected_job_type: '',
      selected_industry: '',
      industries: [],
      which_resume: 'existing_resume',
      categories: [
        { text: 'Administration, business and management', value: 1 },
        { text: 'Alternative therapies', value: 2 },
        { text: 'Animals, land and environment', value: 3 },
        { text: 'Computing and ICT', value: 4 },
        { text: 'Construction and building', value: 5 },
        { text: 'Design, arts and crafts', value: 6 },
        { text: 'Education and training', value: 7 },
        { text: 'Engineering', value: 8 },
        { text: 'Facilities and property services', value: 9 },
        { text: 'Financial services', value: 10 },
        { text: 'Garage services', value: 11 },
        { text: 'Hairdressing and beauty', value: 12 },
        { text: 'Healthcare', value: 13 },
        { text: 'Heritage, culture and libraries', value: 14 },
        { text: 'Hospitality, catering and tourism', value: 15 },
        { text: 'Legal and court services', value: 16 },
        { text: 'Manufacturing and production', value: 17 },
        { text: 'Performing arts and media', value: 18 },
        { text: 'Retail and customer services', value: 19 },
        { text: 'Science, mathematics and statistics', value: 20 },
        { text: 'Security, uniformed and protective services', value: 21 },
        { text: 'Social sciences and religion', value: 22 },
        { text: 'Sport and leisure', value: 23 },
        { text: 'Transport, distribution and logistics', value: 24 },
      ],
      job_types: [
        { text: 'Part-time', value: 'parttime' },
        { text: 'Full-time', value: 'fulltime' },
        { text: 'Internship/Traineeship', value: 'internship_traineeship' },
        { text: 'Freelance', value: 'freelance' },
        { text: 'Permanent', value: 'permanent' },
        { text: 'Contract', value: 'contract' },
        { text: 'Flexi-work', value: 'flexiwork' },
        { text: 'Temporary', value: 'temporary' },
      ],

      selected_location: '',
      selected_event_title: '',
      all_locations: [],
      unfiltered_data: [],
      application_bypass: false,
      suggestions: [],
      aff_id: '',
      applied: false,

      mcf_message:
        'All information on jobs is published in good faith. e2i does not make any warranties about the completeness, reliability and accuracy of this information. From the jobs portal, you can visit other websites by following hyperlinks on the job ads to external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove any dead/unrelated/inappropriate content or links.',
    }
  },

  watch: {
    event_id(val) {},
    selected_event_title: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },
    select_tags: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },
    select_date: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },
    select_company: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },
    selected_location: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },
    selected_job_type: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },

    selected_industry: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },

    select_event_type: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },
    select_date: {
      handler(val, oldVal) {
        this.filtered_data()
      },
    },
    popup_quiz: {
      handler(val, oldVal) {
      },
    },
    deep: true,
  },
  created() {
    // const { token } = this.$store.state.auth.ActiveUser || {}
    if (this.$route.params.dashboard === 'dashboard') {
      this.showLayout = false
    } else {
      this.showLayout = true
    }

    if (this.inheritedEventID) {
      this.event_id = this.inheritedEventID
      localStorage.setItem('track_job_id', this.event_id)
    } else {
      const event_id = this.$route.params.eventid
      this.event_id = event_id
      localStorage.setItem('track_job_id', this.event_id)
    }

    const { query } = this.$route

    if (query.aff_id) {
      this.aff_id = query.aff_id
      this.updateAff()
    }

    this.email_address = this.$store.state.auth.ActiveUser
      ? this.$store.state.auth.ActiveUser.email
      : ''
    if (this.event_id) {
      this.industries = allIndustries
      // this.$http.defaults.headers.common["Authorization"] = `Token ${token}`;

      this.getEvent(this.event_id)

      this.isLoading = true
    }
  },
  methods: {
    checkForm(val = false) {
      this.popup_quiz = val
    },
    applicationUpdate(value) {
      this.registered = value
    },
    activateTypeForm(val) {
      this.popupProfileQuestions = val
    },
    getQuestions(val) {
      this.popup_quiz = true
      this.popup_questions = val.questions
      this.save_nric = val.save_nric
    },
    cleanLocation(location, zone) {
      let { address } = location
      const { country } = location
      address = !this.$isEmpty(address) ? address.replace(`${country} `, '') : address

      let currentZone = zone
      if (location.postal_code) {
        if (location.postal_code === '000000' || location.postal_code === '999999') {
          currentZone = 'Islandwide'
        } else if (currentZone === undefined || currentZone === null) {
          currentZone = 'NA'
        }
      }
      let data = `${currentZone} (${location.postal_code})`.replace('999999', '').replace(' null', '').replace('null', '')
      data = data.replace('(null)', 'NA')
      return data
    },
    fireRedirect(item) {
      window.open(item.redirection_url, '_blank')
    },
    updateAff() {
      const all_data = { aff_id: this.aff_id, platform }
      this.$http
        .put('/api/aff-stats', { data: all_data })
        .then(response => {})
        .catch(error => {})
    },
    goBack() {
      if (this.prevRoute && this.$isNotEmpty(this.prevRoute.name)) {
        this.$router.go(-1)
      } else if (this.$isNotEmpty(this.event_data.event_id)) {
        if (this.$route.params.dashboard === 'dashboard') {
          window.location.href = `/events-list/${this.event_data.event_id}_g`
        } else {
          window.location.href = `/listings/${this.event_data.event_id}_g`
        }
      } else if (this.$route.params.dashboard === 'dashboard') {
        window.location.href = '/events-list'
      } else {
         window.location.href = '/listings'
      }
    },
    capitalize(item) {
      if (item) {
        item = item.replace('_', '/')
        return upperFirst(item)
      }
      return ''
    },
    onCopy(event_id) {
      this.copyTextToClipboard(`${window.location.origin}/listing/${event_id}`)
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Job Link Alert',
            icon: 'AlertCircleIcon',
            text: 'Job link copied successfully',
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        () => {},
        err => {},
      )
    },
    suggestedLink(event_id) {
      const job_link = `${window.location.origin}/listing/${event_id}`
      window.open(job_link, '_blank')
    },
    cleanCycles(dirty_cycle) {
      if (dirty_cycle) {
        dirty_cycle = dirty_cycle.toLowerCase()
        const equiv_cycles = {
          yearly: 'per Year',
          monthly: 'per Month',
          weekly: 'per Week',
          daily: 'per Day',
          hourly: 'per Hourly',
        }

        return equiv_cycles[dirty_cycle]
      }
    },
    cleanedJobDetails(dirty_text) {
      if (!this.$isEmpty(dirty_text)) {
        let cleaned_text
        try {
          cleaned_text = dirty_text
            .replace('Job Description', '')
            .replace('Job Requirements', '')
        } catch (error) {
          cleaned_text = dirty_text
        }

        const result = cleaned_text.split('\n').filter(e => e.length > 3)
        return result
      }
      return ''
    },
    filtered_data() {
      const filtered_inside_jobs = []

      for (const job of this.unfiltered_data) {
        if (this.filter_events(job.details)) {
          filtered_inside_jobs.push(job)
          this.description_manager = job.details
        }
      }

      if (filtered_inside_jobs.length === 0) {
        this.description_manager = {}
      } else {
        this.description_manager = filtered_inside_jobs[0].details
      }

      this.inside_data = filtered_inside_jobs
    },
    filter_events(data) {
      let new_events = false

      const location = this.selected_location.length > 0
        ? this.selected_location.includes(data.work_location)
        : true

      const tags = this.select_tags.length > 0
        ? this.select_tags.some(v => data.tags.includes(v))
        : true

      const event_type = this.select_event_type.length > 0
        ? this.select_event_type.includes(data.event_category)
        : true

      const job_type = this.selected_job_type.length > 0
        ? this.selected_job_type.some(v => data.preferences.includes(v))
        : true

      const date = this.select_date.length > 0
        ? this.select_date.includes(data.start_date)
        : true

      const title = this.selected_event_title.length > 0
        ? data.position
          .toLowerCase()
          .includes(this.selected_event_title.toLowerCase())
        : true

      if (tags && location && event_type && job_type && date && title) {
        new_events = true
      }

      return new_events
      // this.events_data = new_events;
    },

    restoreCreds() {
      const { token } = this.$store.state.auth.ActiveUser || {}
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
    },

    changeDescription(details) {
      this.description_manager = details
      this.$forceUpdate()
    },

    alreadyRegistered() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Job Application Update',
            icon: 'AlertCircleIcon',
            text: 'You have already applied for this job.',
            variant: 'warning',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },

    bookEvent() {
      if (this.book.text === 'Applied') {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Job Application Update',
              icon: 'AlertCircleIcon',
              text: 'You have already applied for this job.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      } else if (this.email_address.length > 4 && this.fullname.length > 3) {
        const all_data = {
          event_id: this.event_id,
          email_address: this.email_address,
          fullname: this.fullname,
          approved: false,
          interviewed: false,
          company: this.$store.state.auth.ActiveUser.company_name,
        }
        this.$http
          .post('/api/book', { data: all_data })
          .then(response => {
            const { success } = response.data
            if (success) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Registration Success',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              // this.book.text = "Registered";
              // this.book.color = "success";
              this.popupReg = false
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Registration Failed',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {})
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Invalid data',
              icon: 'AlertCircleIcon',
              text: 'Please enter a valid email address or fullname',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    getEvent(job_id) {
      const { email } = this.$store.state.auth.ActiveUser || {}
      this.current_user = email
      this.restoreCreds()
      this.$http
        .get(`/api/job/${job_id}`)
        .then(response => {
          if (response.data.success) {
            const item = response.data.output
            this.registered = response.data.registered

            if (this.$isNotEmpty(item.employment_type) && typeof item.employment_type === 'string') {
              if (item.employment_type.includes(',')) {
                item.employment_type = item.employment_type.split(',').map(dt => dt.trim())
              } else {
                item.employment_type = [item.employment_type]
              }
            }
            this.event_data = item

            // const isRegistered = this.checkIDRegistered(this.registered)

            this.specified_event = item
            this.suggestions = response.data.suggestions

            this.jsonld = {
              '@context': 'https://schema.org/',
              '@type': 'JobPosting',
              title: item.position,
              description: `${item.description}\n${item.requirements}`,
              identifier: {
                '@type': 'PropertyValue',
                name: item.company_name,
                value: item.id,
              },
              datePosted: item.created_on,
              validThrough: item.expires_on,
              applicantLocationRequirements: {
                '@type': 'Country',
                name: 'Singapore',
              },
              jobLocation: {
                '@type': 'Place',
                address: {
                  '@type': 'PostalAddress',
                  addressCountry: 'SG',
                },
              },
              baseSalary: {
                '@type': 'MonetaryAmount',
                currency: 'SGD',
                value: {
                  '@type': 'QuantitativeValue',
                  value: item.max_salary,
                  unitText: 'MONTH',
                },
              },
              jobLocationType: item.remote ? 'TELECOMMUTE' : '',
              employmentType:
                item.job_type === 'fulltime' ? 'FULL_TIME' : 'PART_TIME',
              hiringOrganization: {
                '@type': 'Organization',
                name: item.company_name,
                sameAs: 'http://dashboard.headhuntershq.com/listings',
                logo: item.company_image,
              },
            }

            // if (this.$route.query.quiz === "true") {
            // if(!isRegistered){
            //  this.joinEvent(item)
            // }
            // }
            // this.$store.commit("UPDATE_COMPANY_IMAGE", this.specified_event.organiser_image);
            this.companyImage = this.specified_event.organiser_image
            // this.$emit('image-update', this.specified_event.organiser_image)
          }
          this.isLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },
    cleanerIntervals(event_details) {
      if (event_details.start_date === event_details.end_date) {
        return `Happening on ${event_details.cleaned_start_date}, ${event_details.cleaned_start_time}`
      }
      return `Happening from ${event_details.cleaned_start_date}, ${event_details.cleaned_start_time} to ${event_details.cleaned_end_date}, ${event_details.cleaned_end_time}`
    },
    checkIDRegistered(registered, job_id) {
      const id = this.$store.state.auth.ActiveUser
        ? this.$store.state.auth.ActiveUser.id
        : null

      if (registered) {
        if (Object.keys(registered).includes(job_id)) {
          return registered[job_id].includes(id)
        }
        return false
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>

  .blurred-image {
  filter: blur(6px);
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: white;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #ea5455;
  z-index: 1000; /* Bring the element to the front */
}

  .footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px; /* Height of the footer */
  text-align: left;
}
.job-view, .suggested-job {
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }

  .mt-1 {
    margin-top: .5rem !important;
  }
}
div {
  white-space: pre-wrap;
}

.bullet-points {
  list-style-type: square;
}

.image-size {
  max-height: 540px;
  max-width: 1200px;
  object-fit: contain;
}

.icon-header {
  margin-left: 5px;
  margin-top: -1px;
}
</style>
